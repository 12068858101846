import React from "react";
import { Link } from "react-router-dom";
import LogoLight from "../../resources/images/triphome/logolight.svg";

export default function Footer({ isMobile }) {
  const linkStyle = {
    textDecoration: 'none',
    color: 'white',
    fontSize: '14px',
    fontFamily: 'sans-serif'
  };

  return (
    <div
      style={{
        marginTop: isMobile ? 0 : 91,
        paddingBottom: isMobile ? 57 : 148,
        display: isMobile ? "" : "flex",
        justifyContent: "space-between",
        flexWrap: isMobile ? "wrap" : "nowrap",
      }}
    >
      <div>
        <img src={LogoLight} style={{ height: 30 }} />
        <p
          className="footer_p1"
          style={{ marginTop: isMobile ? 10 : 21, maxWidth: 382 }}
        >
          Iconic trips with Iconic <br />
          <span className="footer_p2">people</span>
        </p>
      </div>
      <div style={{ marginTop: isMobile ? 60 : 0 }}>
        <p className="footer_p3">Legal</p>
        <div style={{ marginTop: isMobile ? 16 : 18.28 }}>
          <Link to="/termsandconditions" style={linkStyle}>
            <p style={{ marginBottom: 12 }}>Terms and conditions</p>
          </Link>
          <Link to="/refundandcancellation" style={linkStyle}>
            <p style={{ marginBottom: 12 }}>Refund and cancellation</p>
          </Link>
          <Link to="/contactus" style={linkStyle}>
            <p style={{ marginBottom: 12 }}>Contact us</p>
          </Link>
          <Link to="/privacypolicy" style={linkStyle}>
            <p>Privacy policy</p>
          </Link>
        </div>
      </div>
    </div>
  );
}
