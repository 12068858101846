import React, { useEffect } from 'react';
import './Privacy.css';

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="privacy-container">
      <h1 className="privacy-title">Privacy Policy</h1>
      <p className="privacy-last-updated">Last updated on 14-Oct-2024</p>
      <div className="privacy-content">
        <p>This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of Your information when You respond to
        our advertisements and tells You about Your privacy rights and how the
        law protects You. We use Your Personal Data to contact and support
        you, as well as to provide and improve the Service. By using the
        Service, You agree to the collection and use of information in
        accordance with this Privacy Policy.</p>

        <h2>Interpretation and Definitions</h2>
        
        <h3>Interpretation</h3>
        <p>The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions
        shall have the same meaning regardless of whether they appear in
        singular or in plural.</p>

        <h3>Definitions</h3>
        <p>For the purposes of this Privacy Policy:</p>
        <ul>
          <li><strong>Company</strong> (referred to as either "the Company",
          "We", "Us" or "Our" in this Agreement) refers to Supersquad.</li>
          
          <li><strong>Device</strong> means any device that can access the
          Service such as a computer, a cellphone or a digital tablet.</li>
          
          <li><strong>Personal Data</strong> is any information that relates to
          an identified or identifiable individual.</li>
          
          <li><strong>Service</strong> refers to the advertisement, website, or
          application.</li>
          
          <li><strong>Service Provider</strong> means any natural or legal
          person who processes the data on behalf of the Company. It refers
          to third-party companies or individuals employed by the Company to
          facilitate the Service, to provide the Service on behalf of the
          Company, to perform services related to the Service or to assist
          the Company in analyzing how the Service is used.</li>
          
          <li><strong>Usage Data</strong> refers to data collected
          automatically, either generated by the use of the Service or from
          the Service infrastructure itself (for example, the duration of a
          page visit).</li>
          
          <li><strong>You</strong> means the individual accessing or using the
          Service, or the company, or other legal entity on behalf of which
          such individual is accessing or using the Service, as applicable.</li>
        </ul>

        <h2>Collecting and Using Your Personal Data</h2>
        
        <h3>Types of Data Collected</h3>
        <h4>Personal Data</h4>
        <p>While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or
        identify You. Personally identifiable information may include, but is
        not limited to:</p>
        <ul>
          <li>First name and last name</li>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Location details such as your address, city, or country</li>
          <li>Any other data such as personal preferences, requirements, or comments</li>
        </ul>

        <h3>Use of Your Personal Data</h3>
        <p>The Company may use Personal Data for the following purposes:</p>
        <ul>
          <li><strong>To provide and maintain our Service</strong>, including to
          monitor the usage of our Service.</li>
          
          <li><strong>To manage Your Account:</strong> to manage Your
          registration as a user of the Service.</li>
          
          <li><strong>For the performance of a contract:</strong> the
          development, compliance and undertaking of the purchase contract.</li>
          
          <li><strong>To contact You:</strong> To contact You by email,
          telephone calls, SMS, or other equivalent forms of electronic
          communication.</li>
          
          <li><strong>To provide You</strong> with news, special offers and
          general information about other goods, services and events.</li>
          
          <li><strong>To manage Your requests:</strong> To attend and manage
          Your requests to Us.</li>
          
          <li><strong>For business transfers:</strong> We may use Your
          information to evaluate or conduct a merger, divestiture,
          restructuring, reorganization, dissolution, or other sale.</li>
          
          <li><strong>For other purposes:</strong> We may use Your information
          for other purposes, such as data analysis, identifying usage
          trends.</li>
        </ul>

        <h3>Transfer of Your Personal Data</h3>
        <p>Your information, including Personal Data, is processed at the
        Company's operating offices and in any other places where the parties
        involved in the processing are located. Your consent to this Privacy
        Policy followed by Your submission of such information represents Your
        agreement to that transfer.</p>

        <h3>Disclosure of Your Personal Data</h3>
        <h4>Law enforcement</h4>
        <p>Under certain circumstances, the Company may be required to disclose
        Your Personal Data if required to do so by law or in response to valid
        requests by public authorities.</p>

        <h4>Other legal requirements</h4>
        <p>The Company may disclose Your Personal Data in the good faith belief
        that such action is necessary to:</p>
        <ul>
          <li>Comply with a legal obligation</li>
          <li>Protect and defend the rights or property of the Company</li>
          <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
          <li>Protect the personal safety of Users of the Service or the public</li>
          <li>Protect against legal liability</li>
        </ul>

        <h2>Security of Your Personal Data</h2>
        <p>The security of Your Personal Data is important to Us, but remember
        that no method of transmission over the Internet, or method of
        electronic storage is 100% secure. While We strive to use commercially
        acceptable means to protect Your Personal Data, We cannot guarantee
        its absolute security.</p>

        <h2>Links to Other Websites</h2>
        <p>Our Service may contain links to other websites that are not operated
        by Us. If You click on a third party link, You will be directed to
        that third party's site. We strongly advise You to review the Privacy
        Policy of every site You visit.</p>

        <p>We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.</p>

        <h2>Changes to this Privacy Policy</h2>
        <p>We may update Our Privacy Policy from time to time. We will notify You
        of any changes by posting the new Privacy Policy on this page.</p>

        <p>You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.</p>

        <h2>Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, You can contact us
        via email at tech@gosupersquad.com</p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
