import React, { useEffect } from 'react';
import './ContactUs.css';

function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contact-us-container">
      <h1 className="contact-title">Contact Us</h1>
      <p className="contact-last-updated">Last updated on 16-12-2024 12:52:00</p>
      <div className="contact-content">
        <p>You may contact us using the information below:</p>
        <div className="contact-info">
          <p><strong>Merchant Legal entity name:</strong> ZIRECTLY TECHNOLOGIES PRIVATE LIMITED</p>
          <p><strong>Registered Address:</strong> 704 7TH FLOOR, PALM COURT, MG ROAD, GURUGRAM, Haryana, PIN: 122007</p>
          <p><strong>Operational Address:</strong> 704 7TH FLOOR, PALM COURT, MG ROAD, GURUGRAM, Haryana, PIN: 122007</p>
          <p><strong>Telephone No:</strong> <a href="tel:+918930700021">+91 89307 00021</a></p>
          <p><strong>E-Mail ID:</strong> <a href="mailto:akash@gosupersquad.com">akash@gosupersquad.com</a></p>
        </div>
      </div>
    </div>
  );
}

export default ContactUs; 